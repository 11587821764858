import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchHelpfulLinks = () => tcWrap(async () => {
  const response = await apiClient.get('helpful_links').catch(error => {
    handleError(error)
    return null // Explicitly return null or undefined
  })

  const helpful_links = response?.helpful_links || []
  const total = response?.meta?.total || 0

  return {helpful_links, total}
})
