import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchNewsItems = () => tcWrap(async () => {
  const response = await apiClient.get('news_items').catch(error => {
    handleError(error)
    return null // Return null to indicate an error
  })

  const news_items = response?.news_items || []
  const total = response?.meta?.total || 0

  return {news_items, total}
})
