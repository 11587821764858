import tcWrap from '../../utilities/generic-helpers/tcWrap'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchPermissions = () => tcWrap(async () => {
  const response = await apiClient
    .get('users/permission', {retryWithClientCredentials: true})
    .catch(error => {
      handleError(error)
      return null // Return null to indicate an error
    })

  return response?.permission || null // Default to null if permission is missing
})
